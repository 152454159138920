import React, { useState, useEffect, useCallback } from 'react';
import MetaMaskOnboarding from '@metamask/onboarding';
import detectEthereumProvider from '@metamask/detect-provider';
import './App.css';

// see: https://eips.ethereum.org/EIPS/eip-3085
const NFTGEN_TESTNET = {
  chainId: '0xaf894c',
  chainName: 'NFTGen Testnet',
  nativeCurrency: {
    name: 'GateCoin',
    symbol: 'GTC',
    decimals: 18
  },
  rpcUrls: ['https://rpc.modchain.net/testnet.nftgen.io/rpc'],
  blockExplorerUrls: ['https://explore.modchain.net/testnet.nftgen.io/'],
};

function NoEthereum() {
  const startOnboarding = function() {
    const onboarding = new MetaMaskOnboarding();
    onboarding.startOnboarding();
  };

  return <p>
    No window.ethereum found, make sure you have MetaMask installed & enabled.
    <br/>
    <button onClick={startOnboarding}>Start onboarding</button>
  </p>;
}

function MyAddress({ethereum}) {
  const [addr, setAddr] = useState(null);

  const doGetAccount = useCallback(async function() {
    const eAddrArray = await ethereum.request({method: "eth_accounts"});
    setAddr(eAddrArray[0]);
  }, [ethereum]);

  useEffect(() => {
    doGetAccount();
  }, [ethereum, doGetAccount]);

  useEffect(() => {
    ethereum.on('accountsChanged', doGetAccount);

    return () => {
      ethereum.removeListener('accountsChanged', doGetAccount);
    };
  }, [ethereum, doGetAccount]);

  const requestAccess = async function() {
    const eAddrArray = await ethereum.request({ method: 'eth_requestAccounts' });
    // if user rejects, we get an exception, so we know we have something good in eAddrArray
    setAddr(eAddrArray[0]);
  };

  if (typeof addr !== 'string') {
    return <button onClick={requestAccess}>Request access to see my address</button>;
  }

  return <>My address is <b>{addr}</b></>;
}

function DeployContract({ethereum}) {
  const [contract, setContract] = useState('');
  const sleep = ms => new Promise(r => setTimeout(r, ms));

  const triggerDeploy = async function() {
    setContract("Sending ...");
    const params = {to:null, from: ethereum.selectedAddress, data: contract};
    const res = await ethereum.request({method:'eth_sendTransaction', params:[params]});
    console.log("transaction = "+res);
    setContract("Waiting for mining...");

    for(let i = 0; i < 60; i+= 1) {
      let contractReceipt = await ethereum.request({method:'eth_getTransactionReceipt', params: [res]});
      if (contractReceipt !== null) {
        console.log(contractReceipt);
        setContract(contractReceipt.contractAddress);
        return;
      }
      await sleep(1000);
    }
  };

  return <>
    <h1>Deploy contract</h1>
    <p>Input contract to deploy:</p>
    <p><textarea value={contract} onChange={ev => setContract(ev.target.value)}/></p>
    <p><button onClick={triggerDeploy}>Deploy</button></p>
  </>;
}

function AddAsset({ethereum, contract, name, digits=18}) {
  const assetParams = {
    type: 'ERC20',
    options: {
      address: contract,
      'symbol': name,
      decimals: digits,
      // image
    },
  };

  const triggerAdd = async function() {
    await ethereum.request({method: 'wallet_watchAsset', params: assetParams});
  };

  return <button onClick={triggerAdd}>Add {name}</button>;
}

function NFTGen({ethereum}) {
  const addTestNetwork = async function() {
    // TODO catch errors, report success/failure
    await ethereum.request({method: 'wallet_addEthereumChain', params: [NFTGEN_TESTNET]});
  };


  return (
<>
  <p>
    <button onClick={addTestNetwork}>Add testnet to MetaMask/etc</button><br/>
    <AddAsset ethereum={ethereum} contract="0x1A1e6bf848Dd8eE149Ed39e5c5faEcb703d9c8d4" name="NGT"/>
  </p>
  <p><MyAddress ethereum={ethereum}/></p>
  <DeployContract ethereum={ethereum}/>
</>
  );
}

function App() {
  const [ethereum, setEthereum] = useState(null);

  const doGetProvider = async function() {
    setEthereum(await detectEthereumProvider());
  };

  useEffect(() => {
    doGetProvider();
  }, []);


  if ((typeof ethereum === 'undefined') || (ethereum === null)) {
    return <div className="App"><NoEthereum/></div>;
  }

  return <div className="App"><NFTGen ethereum={ethereum}/></div>;
}

export default App;
